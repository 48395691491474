import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border my-4 border-[#121212] rounded-xl px-6">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-semibold leading-7 text-left text-[#121212]">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="29.75"
                cy="29.5"
                r="29.5"
                transform="rotate(90 29.75 29.5)"
                fill="#121212"
              />
              <path
                d="M29.8929 34.9609C30.0268 34.9609 30.1775 34.894 30.2779 34.7935L38.0792 26.9922C38.1797 26.8917 38.2467 26.7411 38.2467 26.6071C38.2467 26.4732 38.1797 26.3225 38.0792 26.2221L37.2422 25.385C37.1417 25.2846 36.9911 25.2176 36.8571 25.2176C36.7232 25.2176 36.5725 25.2846 36.4721 25.385L29.8929 31.9643L23.3136 25.385C23.2132 25.2846 23.0625 25.2176 22.9286 25.2176C22.7779 25.2176 22.644 25.2846 22.5435 25.385L21.7065 26.2221C21.606 26.3225 21.5391 26.4732 21.5391 26.6071C21.5391 26.7411 21.606 26.8917 21.7065 26.9922L29.5078 34.7935C29.6083 34.894 29.7589 34.9609 29.8929 34.9609Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30.25" cy="29.5" r="29.5" fill="#121212" />
              <path
                d="M35.7109 29.3571C35.7109 29.2232 35.644 29.0725 35.5435 28.9721L27.7422 21.1708C27.6417 21.0703 27.4911 21.0033 27.3571 21.0033C27.2232 21.0033 27.0725 21.0703 26.9721 21.1708L26.135 22.0078C26.0346 22.1083 25.9676 22.2589 25.9676 22.3929C25.9676 22.5268 26.0346 22.6775 26.135 22.7779L32.7143 29.3571L26.135 35.9364C26.0346 36.0368 25.9676 36.1875 25.9676 36.3214C25.9676 36.4721 26.0346 36.606 26.135 36.7065L26.9721 37.5435C27.0725 37.644 27.2232 37.7109 27.3571 37.7109C27.4911 37.7109 27.6417 37.644 27.7422 37.5435L35.5435 29.7422C35.644 29.6417 35.7109 29.4911 35.7109 29.3571Z"
                fill="white"
              />
            </svg>
          )}
        </span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-3xl  font-semibold leading-10 text-center tracking-tight">
          Frequently Asked Questions FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="Do I Need a Deposit for My Car Rental?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            For most of our vehicles, A $400 is required when you book with us.
            Your deposit is 75% refundable, as long as the vehicle comes back
            damage, smoke and pet stain free. $100 of the deposit is kept for
            the cleaning fee. A $1000 deposit is required on our Motorhome and
            Exotic sports cars.
          </span>
        </Section>
        <Section
          title="What's Your Cancellation Policy?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            We offer a flexible cancellation policy. Cancel for free up to 7
            days before your pickup time. Cancellations 2-6 days before
            reservation are 50% refundable and please note, cancellations made
            less than 48 hours before pickup are non-refundable.
          </span>
        </Section>
        <Section
          title="What Rental Coverage Options Are Available?"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            At Black Rock Rentals, we have a wide range of coverage options to
            fit everyone's needs, ensuring you get great value. We recommend
            reviewing your options on our coverage page to find the perfect fit
            for your journey.
          </span>
        </Section>
        <Section
          title="How Long is the Minimum Rental Period?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            The minimum rental period is 2 hours, making it convenient for short
            trips and long adventures alike.
          </span>
        </Section>
        <Section
          title="What Documents Do I Need to Rent a Car?"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            Please bring a valid drivers license, credit card in your name,
            proof of active full coverage insurance at pickup. If you do not
            have a credit card, we have an option to rent with a debit card or
            cash with larger deposit. If you dont have current full coverage
            insurance, supplemental insurance can be purchased. For more
            information, check our terms and conditions page.
          </span>
        </Section>
        <Section
          title="Are There Age Requirements for Renting a Vehicle?"
          isOpen={openSectionIndex === 6}
          onToggle={() => handleToggle(6)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            Yes. The minimum age to rent a vehicle is 18 years, except for
            Luxury, Premium, Exotic or Sports cars, which require a minimum age
            of 25. Renters aged 18-20 can rent with a daily very young driver
            fee of $50 per day added and age 21-24 can rent with a $25 young
            drivers fee.
          </span>
        </Section>
        <Section
          title="What Payment Options Are Accepted?"
          isOpen={openSectionIndex === 7}
          onToggle={() => handleToggle(7)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            We accept payments via Credit Cards, Debit Cards, Apple Pay, Paypal,
            Venmo & Cash.
          </span>
        </Section>
        <Section
          title="Can I Check Rates Without Making a Reservation?"
          isOpen={openSectionIndex === 8}
          onToggle={() => handleToggle(8)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            Absolutely! Browse our website freely to find the best rates and
            options that suit your needs without any obligation to book.
          </span>
        </Section>
        <Section
          title="How Will I Know My Reservation is Confirmed?"
          isOpen={openSectionIndex === 9}
          onToggle={() => handleToggle(9)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#121212B2]">
            You'll receive a confirmation email and see a confirmation message
            on our website once your booking is successfully made.
          </span>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
