import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { FooterLink } from "data/types";
import React from "react";
import { globalJson } from "global/global_json";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: FooterLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Address",
  //   menus: [
  //     {
  //       // href: globalJson.addressLink,
  //       label: globalJson.address,
  //     },
  //   ],
  // },
  {
    id: "2",
    title: "Link",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/vehicles", label: "Our Fleet" },
      { href: "/contact", label: "Contact Us" },
    ],
  },
  {
    id: "1",
    title: "Contact Us",
    menus: [{ label: globalJson.phone }, { label: globalJson.email }],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm lg:ml-16 sm:ml-0">
        <h2 className="font-bold text-white dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.href ? (
                <a
                  className="text-[#8A99B4] dark:text-neutral-300 hover:text-white dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              ) : (
                <span className="text-[#8A99B4] dark:text-neutral-300 break-words">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 border-t border-neutral-200 dark:border-neutral-700 bg-black">
      <div className="container grid grid-cols-1 gap-y-10 gap-x-5 sm:gap-x-8  lg:grid-cols-3 lg:gap-x-10 py-12 ">
        <div className="grid grid-cols-1 gap-5 col-span-2 md:col-span-3 lg:md:col-span-1 lg:flex lg:flex-col">
          <h2 className="text-white font-bold">About Us</h2>
          <span className="text-[#8A99B4]">
            Our Rental Copmany can help you assess your daily rental needs in
            afforadable rates.
          </span>
          {/* <div className="col-span-2 md:col-span-1">
            <Logo />
          </div> */}
          <div className="hidden lg:flex  items-center gap-3">
            {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
            <a href="https://www.facebook.com/">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="40" height="40" rx="20" fill="white" />
                <path
                  d="M23.2588 20.625H21.208V26.75H18.4736V20.625H16.2314V18.1094H18.4736V16.168C18.4736 13.9805 19.7861 12.75 21.7822 12.75C22.7393 12.75 23.751 12.9414 23.751 12.9414V15.1016H22.6299C21.5361 15.1016 21.208 15.7578 21.208 16.4688V18.1094H23.6416L23.2588 20.625Z"
                  fill="#121212"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/">
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.330078"
                  width="40"
                  height="40"
                  rx="20"
                  fill="white"
                />
                <path
                  d="M20.3252 16.6055C22.0479 16.6055 23.4697 18.0273 23.4697 19.75C23.4697 21.5 22.0479 22.8945 20.3252 22.8945C18.5752 22.8945 17.1807 21.5 17.1807 19.75C17.1807 18.0273 18.5752 16.6055 20.3252 16.6055ZM20.3252 21.8008C21.4463 21.8008 22.3486 20.8984 22.3486 19.75C22.3486 18.6289 21.4463 17.7266 20.3252 17.7266C19.1768 17.7266 18.2744 18.6289 18.2744 19.75C18.2744 20.8984 19.2041 21.8008 20.3252 21.8008ZM24.3174 16.4961C24.3174 16.9062 23.9893 17.2344 23.5791 17.2344C23.1689 17.2344 22.8408 16.9062 22.8408 16.4961C22.8408 16.0859 23.1689 15.7578 23.5791 15.7578C23.9893 15.7578 24.3174 16.0859 24.3174 16.4961ZM26.3955 17.2344C26.4502 18.2461 26.4502 21.2812 26.3955 22.293C26.3408 23.2773 26.1221 24.125 25.4111 24.8633C24.7002 25.5742 23.8252 25.793 22.8408 25.8477C21.8291 25.9023 18.7939 25.9023 17.7822 25.8477C16.7979 25.793 15.9502 25.5742 15.2119 24.8633C14.501 24.125 14.2822 23.2773 14.2275 22.293C14.1729 21.2812 14.1729 18.2461 14.2275 17.2344C14.2822 16.25 14.501 15.375 15.2119 14.6641C15.9502 13.9531 16.7979 13.7344 17.7822 13.6797C18.7939 13.625 21.8291 13.625 22.8408 13.6797C23.8252 13.7344 24.7002 13.9531 25.4111 14.6641C26.1221 15.375 26.3408 16.25 26.3955 17.2344ZM25.083 23.3594C25.4111 22.5664 25.3291 20.6523 25.3291 19.75C25.3291 18.875 25.4111 16.9609 25.083 16.1406C24.8643 15.6211 24.4541 15.1836 23.9346 14.9922C23.1143 14.6641 21.2002 14.7461 20.3252 14.7461C19.4229 14.7461 17.5088 14.6641 16.7158 14.9922C16.1689 15.2109 15.7588 15.6211 15.54 16.1406C15.2119 16.9609 15.2939 18.875 15.2939 19.75C15.2939 20.6523 15.2119 22.5664 15.54 23.3594C15.7588 23.9062 16.1689 24.3164 16.7158 24.5352C17.5088 24.8633 19.4229 24.7812 20.3252 24.7812C21.2002 24.7812 23.1143 24.8633 23.9346 24.5352C24.4541 24.3164 24.8916 23.9062 25.083 23.3594Z"
                  fill="#121212"
                />
              </svg>
            </a>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        {/* <SocialsList1 className="lg:hidden flex items-start space-x-3 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
      </div>
    </div>
  );
};

export default Footer;
