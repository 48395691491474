import React from "react";

const WhyChooseUs = () => {
  const features = [
    {
      id: 1,
      title: "Choose A Car",
      description:
        "View our range of cars, find your perfect car for the coming days.",
    },
    {
      id: 2,
      title: "Make Payment",
      description:
        "Our advisor team is ready to help you with the booking process or any questions.",
    },
    {
      id: 3,
      title: "Enjoy Driving",
      description:
        "Receive the key and enjoy your car. We treat all our cars with respect.",
    },
  ];

  return (
    <section className="py-16 bg-white">
      <div className="max-w-screen-xl mx-auto px-6">
        <div className="space-y-6">
          <h2 className="text-4xl font-bold text-center text-gray-800 ">
            Car Rental <span className="text-primary-200">Process</span>
          </h2>
          <p className="text-center max-w-xl mx-auto">
            Car repair quisque sodales dui ut varius vestibulum drana tortor
            turpis porttiton tellus eu euismod nisl massa nutodio in{" "}
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-24">
          {features.map((feature) => (
            <div
              key={feature.id}
              className={`text-left py-12 px-6 space-y-4 ${
                feature.id === 2 ? "bg-[#0076DF14]" : "bg-[#F5F5F7]"
              }`}
            >
              <h3 className="text-xl font-semibold text-gray-800">
                {feature.title}
              </h3>

              <p className="text-gray-600 text-sm leading-relaxed">
                {feature.description}
              </p>
              <div className="w-12 h-12  flex items-center justify-center rounded-full bg-transparent text-[#0076DF] border border-[#0076DF] text-lg font-bold">
                {feature.id}.
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-12 flex flex-row justify-center items-center space-x-2">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.199219"
            y="0.740112"
            width="20"
            height="20"
            rx="10"
            fill="#0076DF"
          />
          <path
            d="M11.5469 14.1385V14.7245H8.85156V14.1385H9.90625V9.76355H8.87109V9.17761H10.4922V14.1385H11.5469ZM9.90625 8.12292C10.3945 8.12292 10.8047 7.71277 10.8047 7.22449C10.8047 6.71667 10.3945 6.30652 9.90625 6.30652C9.39844 6.30652 8.98828 6.71667 8.98828 7.22449C8.98828 7.71277 9.39844 8.12292 9.90625 8.12292Z"
            fill="white"
          />
        </svg>

        <span className="text-[#555555]">
          If you've never rented a car before, we'll guide you through the
          process.
        </span>
      </div>
    </section>
  );
};

export default WhyChooseUs;
