import React, { FC, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-xl space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-bold text-[#3B3B3B] md:text-4xl xl:text-5xl dark:text-neutral-100">
            We Are More Than
            <br />{" "}
            <span className="text-primary-200">A Car Rental Company</span>
          </h2>
          <span className="block !font-normal !text-md text-[#1B2430] dark:text-neutral-400">
            {subHeading}
          </span>
          <div className="flex items-center space-x-2">
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="26"
                height="26"
                rx="13"
                fill="#FAFAFC"
              />
              <rect
                x="0.5"
                y="0.5"
                width="26"
                height="26"
                rx="13"
                stroke="#0076DF"
              />
              <path
                d="M17.9688 9.13867L12.2539 17.8828L9.07422 15.0254L9.50391 14.5527L12.125 16.8945L17.4316 8.79492L17.9688 9.13867Z"
                fill="#0076DF"
              />
            </svg>

            <span className="font-normal text-[#555555]">
              Sports and Luxury Cars
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="26"
                height="26"
                rx="13"
                fill="#FAFAFC"
              />
              <rect
                x="0.5"
                y="0.5"
                width="26"
                height="26"
                rx="13"
                stroke="#0076DF"
              />
              <path
                d="M17.9688 9.13867L12.2539 17.8828L9.07422 15.0254L9.50391 14.5527L12.125 16.8945L17.4316 8.79492L17.9688 9.13867Z"
                fill="#0076DF"
              />
            </svg>

            <span className="font-normal text-[#555555]">Economy Cars</span>
          </div>
          {location.pathname !== "/about" && (
            <ButtonPrimary onClick={() => navigate("/about")}>
              Read More
            </ButtonPrimary>
          )}

          {!!btnText && (
            <ButtonPrimary className="rounded-md" href="/signup">
              {btnText}
            </ButtonPrimary>
          )}
        </div>
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
