import { getBonzahPDF } from "api/bonzah/bonzah";
import React from "react";
import toast from "react-hot-toast";
import { formatDateToHumanFirendly } from "utils/date-format";

function RentalAgreement({ data }: { data: any }) {
  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Correct options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    };

    // Get the formatted date
    const formattedDate = date.toLocaleString("en-GB", options);

    return formattedDate;
  }
  function toSentenceCase(str: string) {
    if (!str || typeof str !== "string") return str; // Return as is if not a string

    // Convert the first letter to uppercase and the rest to lowercase
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const formatDatetoyear = (isoDateString: Date) => {
    if (!isoDateString) return "Invalid date"; // Handle undefined/null dates
    const dateObj = new Date(isoDateString);
    return dateObj.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  };

  function extractSubstringInBrackets(input: string) {
    if (typeof input !== "string") {
      throw new Error("Input must be a string.");
    }

    // Use a regular expression to match the content inside round brackets
    const match = input.match(/\(([^)]+)\)/);

    // Return the captured content or null if no match
    return match ? match[1] : null;
  }

  console.log(data);
  return (
    <>
      <div className="mt-6 rounded-lg border bg-white p-6 pb-8 pt-5">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-[#00283E]">
              Car Rental Agreement
            </h1>
            <p className="max-96 text-sm text-[#86909C]">
              Please note the following booking Terms and Conditions apply to
              bookings placed directly on the {data?.company?.name} website.
            </p>
          </div>
        </div>

        <div className="mb-4 mt-8 flex flex-col md:flex-row">
          <div className="me-0 flex flex-col items-center justify-center rounded-lg  border-gray-300 p-3 md:me-5 md:w-[150px]">
            <img
              alt="Company Image"
              src={`${data?.company?.company_picture}`}
              height={660}
              width={600}
              className="w-full rounded-lg"
            />
          </div>
          <div className="mt-4 flex flex-col justify-center md:mt-0">
            <h2 className="text-xl font-semibold text-[#00283E]">
              {data?.company?.name}
            </h2>
            <p className="pt-2 text-[#00283E]">{data?.company?.address}</p>
            <p className="pt-2 text-[#00283E]">{data?.company?.phone_no}</p>
            <p className="pt-2 text-[#00283E]">{data?.company?.email}</p>
          </div>
        </div>

        <div>
          <div className="flex flex-col md:flex-row">
            <div className="me-3 w-full">
              <div className="rounded-lg border border-muted">
                <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                  <h4 className="text-lg">Customer Information</h4>
                </div>

                <div className="header border-t border-muted p-5">
                  <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1">
                    <span className="text-base font-bold text-[#777777]">
                      Customer Name
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.first_name
                      }{" "}
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.last_name
                      }
                    </h4>
                  </div>
                </div>

                <div className="header border-t border-muted px-5 py-3">
                  <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                    <span className="text-base font-bold text-[#777777]">
                      Home Address
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.street_address_1
                      }{" "}
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.street_address_2
                      }
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      City
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.city
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      State
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.state
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Zip
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.postal_code
                      }
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Phone
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {data?.booking_data?.customer?.phone_no}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Birth Date
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.dob
                      }
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Drivers License number
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.document_number
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      State
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details?.state
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Expires
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.identity_verification_details
                          ?.document_expiration_date
                      }
                    </h4>
                  </div>
                </div>
              </div>

              <div className="mt-5 rounded-lg border border-muted">
                <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                  <h4 className="text-lg">Customer Insurance Information</h4>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Carrier Name
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.carrier_name
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Agent
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Policy Number
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content.policies[0]
                          ?.carrier_policy_number
                      }
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Expires
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {formatDatetoyear(
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.expiry_date
                      )}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Phone
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {data?.booking_data?.customer?.phone_no}
                    </h4>
                  </div>
                </div>
              </div>

              {/* <div className="mt-5 rounded-lg border border-muted">
                <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                  <h4 className="text-lg">Additional Driver</h4>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Additional Driver Name
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Birth Date
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Drivers License number
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      State
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Expires
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                </div>
              </div> */}

              <div className="mt-5 rounded-lg border border-muted">
                <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                  <h4 className="text-lg">Customer Vehicle Information</h4>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      License number
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]"></h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      State
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] "></h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      VIN
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium  text-[#00283E]">
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.vehicles[0].vin
                      }
                    </h4>
                  </div>
                </div>

                <div className="header border-t border-muted px-5 py-3">
                  <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                    <span className="text-base font-bold text-[#777777]">
                      Year/Make/Model/Color
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E] ">
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.vehicles[0].year
                      }
                      {"/"}
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.vehicles[0].make
                      }
                      {"/"}
                      {
                        data?.booking_data?.customer
                          ?.insurance_verification_data?.content?.policies[0]
                          ?.vehicles[0].model
                      }
                    </h4>
                  </div>
                </div>
              </div>

              {data?.booking_data?.bonzah_purchase_response_data?.data?.coverage_information?.filter(
                (insurance: any) => insurance?.added === true
              )?.length > 0 && (
                <div className="mt-5 rounded-lg border border-muted">
                  <div className="header rounded-lg bg-[#f7f7f7] px-5 py-2">
                    <h4 className="text-lg">Purchased Insurance Information</h4>
                  </div>

                  {data?.booking_data?.bonzah_purchase_response_data?.data?.coverage_information
                    ?.filter((insurance: any) => insurance?.added === true)
                    ?.map((insurance: any, index: any) => (
                      <React.Fragment key={index}>
                        <div className="grid grid-cols-1 gap-0 rounded-lg @md:grid-cols-2">
                          <div className="border border-b-0 border-muted px-5 py-2">
                            <span className="text-base font-bold text-[#777777]">
                              Insurance Provided By
                            </span>
                            <h4 className="rounded-md p-1 ps-3 text-base font-medium">
                              Bonzah
                            </h4>
                          </div>
                          <div className="border border-b-0 border-muted px-5 py-2">
                            <span className="text-base font-bold text-[#777777]">
                              Insurance Name
                            </span>
                            <h4 className="rounded-md p-1 ps-3 text-base font-medium">
                              {insurance?.optional_addon_cover_name}
                            </h4>
                          </div>
                        </div>

                        <div className="theme_font_color border border-gray-300 p-4 text-xl font-medium">
                          <span className="text-base font-bold text-[#777777]">
                            Insurance Agreement
                          </span>
                          <h4
                            className="cursor-pointer rounded-md p-1 ps-3 text-base font-medium underline"
                            onClick={async () => {
                              try {
                                const response: any = await getBonzahPDF(
                                  data?.booking_data?.id,
                                  extractSubstringInBrackets(
                                    insurance?.optional_addon_cover_name?.toLowerCase() ??
                                      ""
                                  ) ?? ""
                                );
                                // Convert the response to a Blob
                                const pdfBlob = await response.blob();

                                // Create a Blob URL
                                const pdfUrl = URL.createObjectURL(pdfBlob);

                                // Open the Blob URL in a new window
                                window.open(pdfUrl, "_blank");
                              } catch (error: any) {
                                toast.error(error.message);
                              } finally {
                              }
                            }}
                          >
                            {insurance?.optional_addon_cover_name}
                          </h4>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              )}

              <div className="mt-5 rounded-lg border border-muted">
                <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                  <h4 className="text-lg">Rental Vehicle Information</h4>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Pickup Date & Time
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {formatDateToHumanFirendly(
                        data?.booking_data?.pick_up_time,
                        data?.booking_data?.timezone
                      )}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Dropoff Date & Time
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {formatDateToHumanFirendly(
                        data?.booking_data?.drop_off_time,
                        data?.booking_data?.timezone
                      )}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Booked At
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {formatDateToHumanFirendly(
                        data?.booking_data?.created_at,
                        data?.booking_data?.timezone
                      )}
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Vehicle ID
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {" "}
                      {data?.booking_data?.fleet?.id}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Vehicle Name
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {data?.booking_data?.fleet?.name}
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-3">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Vehicle VIN
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {data?.booking_data?.fleet?.vin_number}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      License Number
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {" "}
                      {data?.booking_data?.fleet?.plate_number}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Vehicle Colour
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {data?.booking_data?.fleet?.color}
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Minimum Miles
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {" "}
                      {data?.booking_data?.fleet?.miles_min}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Maximum Miles
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {" "}
                      {data?.booking_data?.fleet?.miles_max}
                    </h4>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-0 rounded-lg md:grid-cols-2">
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Miles Allowed
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {" "}
                      {data?.booking_data?.fleet?.miles_include}
                    </h4>
                  </div>
                  <div className="border border-b-0 border-muted px-5 py-3">
                    <span className="text-base font-bold text-[#777777]">
                      Miles Overage
                    </span>
                    <h4 className="rounded-md p-1 ps-3 font-medium text-[#00283E]">
                      {data?.booking_data?.fleet?.miles_overage}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="rounded-lg border border-muted">
              <div className="grid  gap-0 border-t  border-muted ">
                <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                  Bill Summary
                </h4>
              </div>

              <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2 ">
                <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                  Extras
                </h4>
                <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                  {data?.booking_data?.invoice?.extras_total}
                </h4>
              </div>

              {/* <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2">
              <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
              <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                ----
              </h4>
            </div> */}

              <div className="grid grid-cols-2 gap-0 border-t  border-muted md:grid-cols-2">
                <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                  Sub Total
                </h4>
                <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                  {data?.booking_data?.invoice?.total_price_without_security}
                </h4>
              </div>

              {/* <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2 ">
                <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                  Payments
                </h4>
                <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                  ----
                </h4>
              </div>

              <div className="grid grid-cols-2 gap-0 border-t border-muted md:grid-cols-2">
                <h4 className="border-r px-5 py-3 text-lg font-medium text-[#00283E]">
                  Refunds
                </h4>
                <h4 className="px-5 py-3 text-lg font-medium text-[#00283E]">
                  ----
                </h4>
              </div> */}

              <div className="grid grid-cols-2 gap-0 border-t  border-muted md:grid-cols-2">
                <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                  Deposit Held
                </h4>
                <h4 className="rounded-lg bg-[#f7f7f7] px-5 py-3 text-lg">
                  {data?.booking_data?.invoice?.security_deposit_total}
                </h4>
              </div>
            </div>

            <div className="mt-5 rounded-lg border border-muted">
              <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                <h4 className="text-lg">Renters Signature</h4>
              </div>

              <div className="header border-t border-muted px-5 py-3">
                <div className="grid grid-cols-1 gap-3 rounded-lg md:grid-cols-1 lg:gap-1 2xl:gap-1">
                  {/* <span className="text-base font-bold text-[#777777]">
                    Signature
                  </span> */}
                  <h3
                    className="rounded-md p-2 ps-3 font-medium text-[#00283E] italic text-3xl"
                    style={{ fontFamily: "'Dancing Script', cursive" }}
                  >
                    {data?.booking_data?.customer?.identity_verification_details
                      ? toSentenceCase(
                          data?.booking_data?.customer
                            ?.identity_verification_details?.first_name
                        ) +
                        " " +
                        toSentenceCase(
                          data?.booking_data?.customer
                            ?.identity_verification_details?.last_name
                        )
                      : toSentenceCase(
                          data?.booking_data?.customer?.first_name
                        ) +
                        " " +
                        toSentenceCase(data?.booking_data?.customer?.last_name)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded-lg border bg-white p-4">
        <div className="mb-8">
          <h1 className="text-lg font-bold text-[#00283E]">
            Terms & Conditions
          </h1>
          <p className="max-96 text-sm text-[#86909C]">
            {`Please note the following booking Terms and Conditions apply to
         bookings placed directly on the ${data.companyData?.name} website.`}
          </p>
        </div>
        <div className="col-span-12 @container">
          <div className="flex flex-col gap-y-4">
            {data?.content?.terms_condition?.map((item: any, i: number) => {
              return (
                <div className="rounded-lg border border-muted" key={i}>
                  <div className="header rounded-lg bg-[#f7f7f7] px-5 py-3">
                    <h4 className="text-lg">
                      {i + 1}. {item?.title}
                    </h4>
                  </div>

                  <div className="header border-t border-muted p-5 w-full">
                    <div className="grid grid-cols-1 gap-3 rounded-lg w-full">
                      <div
                        className="prose prose-sm text-gray-500 break-words"
                        style={{ maxWidth: "100%" }} // Manually remove the max width
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                    </div>
                  </div>
                  {item?.content_picture === null ||
                  item?.content_picture === "" ||
                  item?.content_picture === "null" ? (
                    ""
                  ) : (
                    <div className="header border-t border-muted p-5">
                      <div className="grid grid-cols-1 gap-3 rounded-lg @md:grid-cols-1">
                        <img
                          alt="terms and conditions image"
                          src={`https://fleet-management-images-upload-be.s3.amazonaws.com/${item?.content_picture}`}
                          height={660}
                          width={600}
                          className="w-full rounded-lg"
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default RentalAgreement;
