import React from "react";
import rightImg from "images/right2.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

function InterstingRenting() {
  const navigate = useNavigate();
  return (
    <div
      className="min-h-56"
      style={{
        background:
          "linear-gradient(182.13deg, #69C7EB 2.27%, #ADDCEE 40.29%, #FFFFFF 98.27%)",
      }}
    >
      <div className="flex flex-col lg:flex-row justify-center items-center space-y-6 p-4">
        <div className="flex-1 max-w-lg p-4 flex flex-col justify-center pl-12 mx-auto space-y-6">
          <span className="text-4xl font-bold">Interested in Renting?</span>
          <p>Don't hesitate and send us a message</p>
          <div className="flex space-x-12 ">
            <ButtonPrimary
              className="mt-4 px-6 py-2  text-white rounded-full"
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </ButtonPrimary>
            <ButtonPrimary
              className="mt-4 px-6 py-2 bg-transparent hover:bg-transparent text-primary-200 border border-primary-200 rounded-full"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Rent Now{" "}
              <svg
                width="11"
                height="10"
                viewBox="0 0 11 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_444_442)">
                  <path
                    d="M10.3564 0.585938V5.87891H9.77051V1.58203L3.69629 7.65625L3.28613 7.22656L9.34082 1.17188H5.04395V0.585938H10.3564Z"
                    fill="#0076DF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_444_442">
                    <rect
                      width="10"
                      height="10"
                      fill="white"
                      transform="matrix(1 0 0 -1 0.942383 10)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ButtonPrimary>
          </div>
        </div>
        <div className="flex-1 p-4">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
}

export default InterstingRenting;
